import React from "react";
import theme from "theme";
import { Theme, Link, Text, Strong, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404/oldone"} />
		<Helmet>
			<title>
				Old game
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/back.jpg?v=2022-01-08T11:10:00.783Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/himmm.png?v=2020-10-03T22:34:30.191Z"} type={"image/x-icon"} />
		</Helmet>
		<Components.Menuu>
			<Override slot="text">
				<Link href="https://ternoxgames.com/" text-decoration-line="initial" color="#000000">
					Ternoxgames.com
				</Link>
			</Override>
		</Components.Menuu>
		<Section padding="-7px 0 100px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Section padding="0px 0 100px 0" sm-padding="40px 0">
				<Override slot="SectionContent" align-items="center" />
				<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
					Невероятные приключения Химари
				</Text>
				<Text as="p" font="--lead" margin="20px 0 0 0">
					<Strong
						text-overflow="clip"
						hyphens="manual"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
					>
						Cюжет
					</Strong>
					: Николас собирает всю группу «Щит», а также Химарьку и сообщает ей, что мир в опасности, так как во владении злых людей находятся осколки метеорита, которые при правильно использовании позволяют управлять людьми. Ну и Химарька, конечно же, отправляется на поиски этих камней. Но всё оказывается не так просто...
					<br />
					<br />
					<Strong
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						overflow-wrap="normal"
					>
						О игре
					</Strong>
					: это платформер с элементами beat 'em up, сделанный в ретро-стиле. Вся музыка в игре полностью выполнена мною (лишь тема из Бесконечного Лета является римейком, тоже моим, кстати).
					<br />
					<br />
					Игра сама по себе довольно хардкорна, изначально планировалось ограничить путешествие Химари тремя жизнями, однако в итоге вы имеете бесконечный запас продолжений, так как некоторые уровни довольно сложные даже после десятой попытки их прохождения:)
					<br />
					<br />
					В игре семь уровней, последний состоит из четырёх частей. Вы увидите многих персонажей из визуальных новелл, помимо «Щита», это Троян и Маруся, Заяц-Король, Ольга Дмитриевна из «Бесконечного лета» и многие другие.
				</Text>
				<Components.Knopa href="https://yadi.sk/d/bB5lBBgZdjHLA" padding="16px 20px 16px 20px" margin="21px 21px 21px 21px">
					DOWNLOAD
				</Components.Knopa>
				<Image src="https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Ku5FVjMLpE0.jpg?v=2021-01-11T16:00:05.310Z" srcSet="https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Ku5FVjMLpE0.jpg?v=2021-01-11T16%3A00%3A05.310Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Ku5FVjMLpE0.jpg?v=2021-01-11T16%3A00%3A05.310Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Ku5FVjMLpE0.jpg?v=2021-01-11T16%3A00%3A05.310Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Ku5FVjMLpE0.jpg?v=2021-01-11T16%3A00%3A05.310Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Ku5FVjMLpE0.jpg?v=2021-01-11T16%3A00%3A05.310Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Ku5FVjMLpE0.jpg?v=2021-01-11T16%3A00%3A05.310Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/Ku5FVjMLpE0.jpg?v=2021-01-11T16%3A00%3A05.310Z&quality=85&w=3200 3200w" sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw" />
				<Image src="https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/eMKxJHnNo5s.jpg?v=2021-01-11T16:00:05.313Z" srcSet="https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/eMKxJHnNo5s.jpg?v=2021-01-11T16%3A00%3A05.313Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/eMKxJHnNo5s.jpg?v=2021-01-11T16%3A00%3A05.313Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/eMKxJHnNo5s.jpg?v=2021-01-11T16%3A00%3A05.313Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/eMKxJHnNo5s.jpg?v=2021-01-11T16%3A00%3A05.313Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/eMKxJHnNo5s.jpg?v=2021-01-11T16%3A00%3A05.313Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/eMKxJHnNo5s.jpg?v=2021-01-11T16%3A00%3A05.313Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/eMKxJHnNo5s.jpg?v=2021-01-11T16%3A00%3A05.313Z&quality=85&w=3200 3200w" sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw" />
				<Image src="https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/cFx4AI6BVdQ.jpg?v=2021-01-11T16:00:05.313Z" srcSet="https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/cFx4AI6BVdQ.jpg?v=2021-01-11T16%3A00%3A05.313Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/cFx4AI6BVdQ.jpg?v=2021-01-11T16%3A00%3A05.313Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/cFx4AI6BVdQ.jpg?v=2021-01-11T16%3A00%3A05.313Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/cFx4AI6BVdQ.jpg?v=2021-01-11T16%3A00%3A05.313Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/cFx4AI6BVdQ.jpg?v=2021-01-11T16%3A00%3A05.313Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/cFx4AI6BVdQ.jpg?v=2021-01-11T16%3A00%3A05.313Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/cFx4AI6BVdQ.jpg?v=2021-01-11T16%3A00%3A05.313Z&quality=85&w=3200 3200w" sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw" />
				<Image src="https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/n_StU1d7-OA.jpg?v=2021-01-11T16:00:05.314Z" srcSet="https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/n_StU1d7-OA.jpg?v=2021-01-11T16%3A00%3A05.314Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/n_StU1d7-OA.jpg?v=2021-01-11T16%3A00%3A05.314Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/n_StU1d7-OA.jpg?v=2021-01-11T16%3A00%3A05.314Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/n_StU1d7-OA.jpg?v=2021-01-11T16%3A00%3A05.314Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/n_StU1d7-OA.jpg?v=2021-01-11T16%3A00%3A05.314Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/n_StU1d7-OA.jpg?v=2021-01-11T16%3A00%3A05.314Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/n_StU1d7-OA.jpg?v=2021-01-11T16%3A00%3A05.314Z&quality=85&w=3200 3200w" sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw" />
				<Image src="https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/KcXvh7FRLQw.jpg?v=2021-01-11T16:00:05.327Z" srcSet="https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/KcXvh7FRLQw.jpg?v=2021-01-11T16%3A00%3A05.327Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/KcXvh7FRLQw.jpg?v=2021-01-11T16%3A00%3A05.327Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/KcXvh7FRLQw.jpg?v=2021-01-11T16%3A00%3A05.327Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/KcXvh7FRLQw.jpg?v=2021-01-11T16%3A00%3A05.327Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/KcXvh7FRLQw.jpg?v=2021-01-11T16%3A00%3A05.327Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/KcXvh7FRLQw.jpg?v=2021-01-11T16%3A00%3A05.327Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/KcXvh7FRLQw.jpg?v=2021-01-11T16%3A00%3A05.327Z&quality=85&w=3200 3200w" sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw" />
			</Section>
		</Section>
	</Theme>;
});